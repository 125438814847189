(function() {
	var duration = 5000,
		animationDuration = 300;

	$('.slideshow').each(function() {
		var slides = $('.slide', this),
			lastSlide = slides.filter(':first, .in').last(),
			index = lastSlide.index(),
			zindex = 10,
			timer, slide, lastSlide;

		if(!slides) { return; }

		function interval(animate, restart) {
			// Fade it in in
			var slide = slides.eq(index++ >= slides.length - 1 ? (index = 0) : index)
				.css({
					'z-index': ++zindex
				});

			function complete() {
				// Reset opacity of last slide
				$.support.transition && lastSlide && lastSlide.removeClass('in');

				// Store the last slide
				lastSlide = slide;
			}

			// Transition or animate
			$.support.transition ?
				slide.addClass('in').one($.support.transition.end, complete) :
				slide.css('opacity', 0).stop(1, 0).fadeTo(animationDuration, 1).queue(complete);

			if (restart !== false) {
				clearTimeout(timer);
				timer = setTimeout(interval, duration);
			}

		}

		slides.bind('mouseenter mouseleave', function(event, i) {
			if (event.type === 'mouseenter') {
				clearTimeout(timer);
			} else {
				timer = setTimeout(interval, duration);
			}

			if (i !== undefined) {
				index = i;
				interval(false, false);
			}
		})
		.triggerHandler('mouseleave');
	})
}());